export default {
	// alt forms
	egg: 1032 + 1,
	pikachubelle: 1032 + 2,
	pikachulibre: 1032 + 3,
	pikachuphd: 1032 + 4,
	pikachupopstar: 1032 + 5,
	pikachurockstar: 1032 + 6,
	pikachucosplay: 1032 + 7,
	unownexclamation: 1032 + 8,
	unownquestion: 1032 + 9,
	unownb: 1032 + 10,
	unownc: 1032 + 11,
	unownd: 1032 + 12,
	unowne: 1032 + 13,
	unownf: 1032 + 14,
	unowng: 1032 + 15,
	unownh: 1032 + 16,
	unowni: 1032 + 17,
	unownj: 1032 + 18,
	unownk: 1032 + 19,
	unownl: 1032 + 20,
	unownm: 1032 + 21,
	unownn: 1032 + 22,
	unowno: 1032 + 23,
	unownp: 1032 + 24,
	unownq: 1032 + 25,
	unownr: 1032 + 26,
	unowns: 1032 + 27,
	unownt: 1032 + 28,
	unownu: 1032 + 29,
	unownv: 1032 + 30,
	unownw: 1032 + 31,
	unownx: 1032 + 32,
	unowny: 1032 + 33,
	unownz: 1032 + 34,
	castformrainy: 1032 + 35,
	castformsnowy: 1032 + 36,
	castformsunny: 1032 + 37,
	deoxysattack: 1032 + 38,
	deoxysdefense: 1032 + 39,
	deoxysspeed: 1032 + 40,
	burmysandy: 1032 + 41,
	burmytrash: 1032 + 42,
	wormadamsandy: 1032 + 43,
	wormadamtrash: 1032 + 44,
	cherrimsunshine: 1032 + 45,
	shelloseast: 1032 + 46,
	gastrodoneast: 1032 + 47,
	rotomfan: 1032 + 48,
	rotomfrost: 1032 + 49,
	rotomheat: 1032 + 50,
	rotommow: 1032 + 51,
	rotomwash: 1032 + 52,
	giratinaorigin: 1032 + 53,
	shayminsky: 1032 + 54,
	unfezantf: 1032 + 55,
	basculinbluestriped: 1032 + 56,
	darmanitanzen: 1032 + 57,
	deerlingautumn: 1032 + 58,
	deerlingsummer: 1032 + 59,
	deerlingwinter: 1032 + 60,
	sawsbuckautumn: 1032 + 61,
	sawsbucksummer: 1032 + 62,
	sawsbuckwinter: 1032 + 63,
	frillishf: 1032 + 64,
	jellicentf: 1032 + 65,
	tornadustherian: 1032 + 66,
	thundurustherian: 1032 + 67,
	landorustherian: 1032 + 68,
	kyuremblack: 1032 + 69,
	kyuremwhite: 1032 + 70,
	keldeoresolute: 1032 + 71,
	meloettapirouette: 1032 + 72,
	vivillonarchipelago: 1032 + 73,
	vivilloncontinental: 1032 + 74,
	vivillonelegant: 1032 + 75,
	vivillonfancy: 1032 + 76,
	vivillongarden: 1032 + 77,
	vivillonhighplains: 1032 + 78,
	vivillonicysnow: 1032 + 79,
	vivillonjungle: 1032 + 80,
	vivillonmarine: 1032 + 81,
	vivillonmodern: 1032 + 82,
	vivillonmonsoon: 1032 + 83,
	vivillonocean: 1032 + 84,
	vivillonpokeball: 1032 + 85,
	vivillonpolar: 1032 + 86,
	vivillonriver: 1032 + 87,
	vivillonsandstorm: 1032 + 88,
	vivillonsavanna: 1032 + 89,
	vivillonsun: 1032 + 90,
	vivillontundra: 1032 + 91,
	pyroarf: 1032 + 92,
	flabebeblue: 1032 + 93,
	flabebeorange: 1032 + 94,
	flabebewhite: 1032 + 95,
	flabebeyellow: 1032 + 96,
	floetteblue: 1032 + 97,
	floetteeternal: 1032 + 98,
	floetteorange: 1032 + 99,
	floettewhite: 1032 + 100,
	floetteyellow: 1032 + 101,
	florgesblue: 1032 + 102,
	florgesorange: 1032 + 103,
	florgeswhite: 1032 + 104,
	florgesyellow: 1032 + 105,
	furfroudandy: 1032 + 106,
	furfroudebutante: 1032 + 107,
	furfroudiamond: 1032 + 108,
	furfrouheart: 1032 + 109,
	furfroukabuki: 1032 + 110,
	furfroulareine: 1032 + 111,
	furfroumatron: 1032 + 112,
	furfroupharaoh: 1032 + 113,
	furfroustar: 1032 + 114,
	meowsticf: 1032 + 115,
	aegislashblade: 1032 + 116,
	xerneasneutral: 1032 + 117,
	hoopaunbound: 1032 + 118,
	rattataalola: 1032 + 119,
	raticatealola: 1032 + 120,
	raichualola: 1032 + 121,
	sandshrewalola: 1032 + 122,
	sandslashalola: 1032 + 123,
	vulpixalola: 1032 + 124,
	ninetalesalola: 1032 + 125,
	diglettalola: 1032 + 126,
	dugtrioalola: 1032 + 127,
	meowthalola: 1032 + 128,
	persianalola: 1032 + 129,
	geodudealola: 1032 + 130,
	graveleralola: 1032 + 131,
	golemalola: 1032 + 132,
	grimeralola: 1032 + 133,
	mukalola: 1032 + 134,
	exeggutoralola: 1032 + 135,
	marowakalola: 1032 + 136,
	greninjaash: 1032 + 137,
	zygarde10: 1032 + 138,
	zygardecomplete: 1032 + 139,
	oricoriopompom: 1032 + 140,
	oricoriopau: 1032 + 141,
	oricoriosensu: 1032 + 142,
	lycanrocmidnight: 1032 + 143,
	wishiwashischool: 1032 + 144,
	miniormeteor: 1032 + 145,
	miniororange: 1032 + 146,
	minioryellow: 1032 + 147,
	miniorgreen: 1032 + 148,
	miniorblue: 1032 + 149,
	miniorindigo: 1032 + 150,
	miniorviolet: 1032 + 151,
	magearnaoriginal: 1032 + 152,
	pikachuoriginal: 1032 + 153,
	pikachuhoenn: 1032 + 154,
	pikachusinnoh: 1032 + 155,
	pikachuunova: 1032 + 156,
	pikachukalos: 1032 + 157,
	pikachualola: 1032 + 158,
	pikachupartner: 1032 + 159,
	lycanrocdusk: 1032 + 160,
	necrozmaduskmane: 1032 + 161,
	necrozmadawnwings: 1032 + 162,
	necrozmaultra: 1032 + 163,
	pikachustarter: 1032 + 164,
	eeveestarter: 1032 + 165,
	meowthgalar: 1032 + 166,
	ponytagalar: 1032 + 167,
	rapidashgalar: 1032 + 168,
	farfetchdgalar: 1032 + 169,
	weezinggalar: 1032 + 170,
	mrmimegalar: 1032 + 171,
	corsolagalar: 1032 + 172,
	zigzagoongalar: 1032 + 173,
	linoonegalar: 1032 + 174,
	darumakagalar: 1032 + 175,
	darmanitangalar: 1032 + 176,
	darmanitangalarzen: 1032 + 177,
	yamaskgalar: 1032 + 178,
	stunfiskgalar: 1032 + 179,
	cramorantgulping: 1032 + 180,
	cramorantgorging: 1032 + 181,
	toxtricitylowkey: 1032 + 182,
	alcremierubycream: 1032 + 183,
	alcremiematchacream: 1032 + 184,
	alcremiemintcream: 1032 + 185,
	alcremielemoncream: 1032 + 186,
	alcremiesaltedcream: 1032 + 187,
	alcremierubyswirl: 1032 + 188,
	alcremiecaramelswirl: 1032 + 189,
	alcremierainbowswirl: 1032 + 190,
	eiscuenoice: 1032 + 191,
	indeedeef: 1032 + 192,
	morpekohangry: 1032 + 193,
	zaciancrowned: 1032 + 194,
	zamazentacrowned: 1032 + 195,
	slowpokegalar: 1032 + 196,
	slowbrogalar: 1032 + 197,
	zarudedada: 1032 + 198,
	pikachuworld: 1032 + 199,
	articunogalar: 1032 + 200,
	zapdosgalar: 1032 + 201,
	moltresgalar: 1032 + 202,
	slowkinggalar: 1032 + 203,
	calyrexice: 1032 + 204,
	calyrexshadow: 1032 + 205,
	growlithehisui: 1032 + 206,
	arcaninehisui: 1032 + 207,
	voltorbhisui: 1032 + 208,
	electrodehisui: 1032 + 209,
	typhlosionhisui: 1032 + 210,
	qwilfishhisui: 1032 + 211,
	sneaselhisui: 1032 + 212,
	samurotthisui: 1032 + 213,
	lilliganthisui: 1032 + 214,
	zoruahisui: 1032 + 215,
	zoroarkhisui: 1032 + 216,
	braviaryhisui: 1032 + 217,
	sliggoohisui: 1032 + 218,
	goodrahisui: 1032 + 219,
	avalugghisui: 1032 + 220,
	decidueyehisui: 1032 + 221,
	basculegionf: 1032 + 222,
	enamorustherian: 1032 + 223,
	taurospaldeacombat: 1032 + 224,
	taurospaldeablaze: 1032 + 225,
	taurospaldeaaqua: 1032 + 226,
	wooperpaldea: 1032 + 227,
	oinkolognef: 1032 + 228,
	palafinhero: 1032 + 229,
	mausholdfour: 1032 + 230,
	tatsugiridroopy: 1032 + 231,
	tatsugiristretchy: 1032 + 232,
	squawkabillyblue: 1032 + 233,
	squawkabillyyellow: 1032 + 234,
	squawkabillywhite: 1032 + 235,
	gimmighoulroaming: 1032 + 236,
	dialgaorigin: 1032 + 237,
	palkiaorigin: 1032 + 238,
	basculinwhitestriped: 1032 + 239,
	ursalunabloodmoon: 1032 + 240,
	ogerponwellspring: 1032 + 241,
	ogerponhearthflame: 1032 + 242,
	ogerponcornerstone: 1032 + 243,
	terapagosterastal: 1032 + 244,
	terapagosstellar: 1032 + 245,

	arceusbug: 1032 + 246,
	arceusdark: 1032 + 247,
	arceusdragon: 1032 + 248,
	arceuselectric: 1032 + 249,
	arceusfairy: 1032 + 250,
	arceusfighting: 1032 + 251,
	arceusfire: 1032 + 252,
	arceusflying: 1032 + 253,
	arceusghost: 1032 + 254,
	arceusgrass: 1032 + 255,
	arceusground: 1032 + 256,
	arceusice: 1032 + 257,
	arceuspoison: 1032 + 258,
	arceuspsychic: 1032 + 259,
	arceusrock: 1032 + 260,
	arceussteel: 1032 + 261,
	arceuswater: 1032 + 262,

	genesectdouse: 1032 + 263,
	genesectshock: 1032 + 264,
	genesectburn: 1032 + 265,
	genesectchill: 1032 + 266,

	silvallybug: 1032 + 267,
	silvallydark: 1032 + 268,
	silvallydragon: 1032 + 269,
	silvallyelectric: 1032 + 270,
	silvallyfairy: 1032 + 271,
	silvallyfighting: 1032 + 272,
	silvallyfire: 1032 + 273,
	silvallyflying: 1032 + 274,
	silvallyghost: 1032 + 275,
	silvallygrass: 1032 + 276,
	silvallyground: 1032 + 277,
	silvallyice: 1032 + 278,
	silvallypoison: 1032 + 279,
	silvallypsychic: 1032 + 280,
	silvallyrock: 1032 + 281,
	silvallysteel: 1032 + 282,
	silvallywater: 1032 + 283,

	// alt forms with duplicate icons
	greninjabond: 658,
	gumshoostotem: 735,
	raticatealolatotem: 1032 + 120,
	marowakalolatotem: 1032 + 136,
	araquanidtotem: 752,
	lurantistotem: 754,
	salazzletotem: 758,
	vikavolttotem: 738,
	togedemarutotem: 777,
	mimikyutotem: 778,
	mimikyubustedtotem: 778,
	ribombeetotem: 743,
	kommoototem: 784,
	sinisteaantique: 854,
	polteageistantique: 855,
	poltchageistartisan: 1012,
	sinistchamasterpiece: 1013,
	ogerpontealtera: 1017,
	ogerponwellspringtera: 1032 + 241,
	ogerponhearthflametera: 1032 + 242,
	ogerponcornerstonetera: 1032 + 243,
	toxtricitylowkeygmax: 1320 + 69,

	// Mega/G-Max
	venusaurmega: 1320 + 0,
	charizardmegax: 1320 + 1,
	charizardmegay: 1320 + 2,
	blastoisemega: 1320 + 3,
	beedrillmega: 1320 + 4,
	pidgeotmega: 1320 + 5,
	alakazammega: 1320 + 6,
	slowbromega: 1320 + 7,
	gengarmega: 1320 + 8,
	kangaskhanmega: 1320 + 9,
	pinsirmega: 1320 + 10,
	gyaradosmega: 1320 + 11,
	aerodactylmega: 1320 + 12,
	mewtwomegax: 1320 + 13,
	mewtwomegay: 1320 + 14,
	ampharosmega: 1320 + 15,
	steelixmega: 1320 + 16,
	scizormega: 1320 + 17,
	heracrossmega: 1320 + 18,
	houndoommega: 1320 + 19,
	tyranitarmega: 1320 + 20,
	sceptilemega: 1320 + 21,
	blazikenmega: 1320 + 22,
	swampertmega: 1320 + 23,
	gardevoirmega: 1320 + 24,
	sableyemega: 1320 + 25,
	mawilemega: 1320 + 26,
	aggronmega: 1320 + 27,
	medichammega: 1320 + 28,
	manectricmega: 1320 + 29,
	sharpedomega: 1320 + 30,
	cameruptmega: 1320 + 31,
	altariamega: 1320 + 32,
	banettemega: 1320 + 33,
	absolmega: 1320 + 34,
	glaliemega: 1320 + 35,
	salamencemega: 1320 + 36,
	metagrossmega: 1320 + 37,
	latiasmega: 1320 + 38,
	latiosmega: 1320 + 39,
	kyogreprimal: 1320 + 40,
	groudonprimal: 1320 + 41,
	rayquazamega: 1320 + 42,
	lopunnymega: 1320 + 43,
	garchompmega: 1320 + 44,
	lucariomega: 1320 + 45,
	abomasnowmega: 1320 + 46,
	gallademega: 1320 + 47,
	audinomega: 1320 + 48,
	dianciemega: 1320 + 49,
	charizardgmax: 1320 + 50,
	butterfreegmax: 1320 + 51,
	pikachugmax: 1320 + 52,
	meowthgmax: 1320 + 53,
	machampgmax: 1320 + 54,
	gengargmax: 1320 + 55,
	kinglergmax: 1320 + 56,
	laprasgmax: 1320 + 57,
	eeveegmax: 1320 + 58,
	snorlaxgmax: 1320 + 59,
	garbodorgmax: 1320 + 60,
	melmetalgmax: 1320 + 61,
	corviknightgmax: 1320 + 62,
	orbeetlegmax: 1320 + 63,
	drednawgmax: 1320 + 64,
	coalossalgmax: 1320 + 65,
	flapplegmax: 1320 + 66,
	appletungmax: 1320 + 67,
	sandacondagmax: 1320 + 68,
	toxtricitygmax: 1320 + 69,
	centiskorchgmax: 1320 + 70,
	hatterenegmax: 1320 + 71,
	grimmsnarlgmax: 1320 + 72,
	alcremiegmax: 1320 + 73,
	copperajahgmax: 1320 + 74,
	duraludongmax: 1320 + 75,
	eternatuseternamax: 1320 + 76,
	venusaurgmax: 1320 + 77,
	blastoisegmax: 1320 + 78,
	rillaboomgmax: 1320 + 79,
	cinderacegmax: 1320 + 80,
	inteleongmax: 1320 + 81,
	urshifugmax: 1320 + 82,
	urshifurapidstrikegmax: 1320 + 83,

	// CAP
	syclant: 1512 + 0,
	revenankh: 1512 + 1,
	pyroak: 1512 + 2,
	fidgit: 1512 + 3,
	stratagem: 1512 + 4,
	arghonaut: 1512 + 5,
	kitsunoh: 1512 + 6,
	cyclohm: 1512 + 7,
	colossoil: 1512 + 8,
	krilowatt: 1512 + 9,
	voodoom: 1512 + 10,
	tomohawk: 1512 + 11,
	necturna: 1512 + 12,
	mollux: 1512 + 13,
	aurumoth: 1512 + 14,
	malaconda: 1512 + 15,
	cawmodore: 1512 + 16,
	volkraken: 1512 + 17,
	plasmanta: 1512 + 18,
	naviathan: 1512 + 19,
	crucibelle: 1512 + 20,
	crucibellemega: 1512 + 21,
	kerfluffle: 1512 + 22,
	pajantom: 1512 + 23,
	jumbao: 1512 + 24,
	caribolt: 1512 + 25,
	smokomodo: 1512 + 26,
	snaelstrom: 1512 + 27,
	equilibra: 1512 + 28,
	astrolotl: 1512 + 29,
	miasmaw: 1512 + 30,
	chromera: 1512 + 31,
	venomicon: 1512 + 32,
	venomiconepilogue: 1512 + 33,
	saharaja: 1512 + 34,
	hemogoblin: 1512 + 35,
	syclar: 1512 + 36,
	embirch: 1512 + 37,
	flarelm: 1512 + 38,
	breezi: 1512 + 39,
	scratchet: 1512 + 40,
	necturine: 1512 + 41,
	cupra: 1512 + 42,
	argalis: 1512 + 43,
	brattler: 1512 + 44,
	cawdet: 1512 + 45,
	volkritter: 1512 + 46,
	snugglow: 1512 + 47,
	floatoy: 1512 + 48,
	caimanoe: 1512 + 49,
	pluffle: 1512 + 50,
	rebble: 1512 + 51,
	tactite: 1512 + 52,
	privatyke: 1512 + 53,
	nohface: 1512 + 54,
	monohm: 1512 + 55,
	duohm: 1512 + 56,
	protowatt: 1512 + 57,
	voodoll: 1512 + 58,
	mumbao: 1512 + 59,
	fawnifer: 1512 + 60,
	electrelk: 1512 + 61,
	smogecko: 1512 + 62,
	smoguana: 1512 + 63,
	swirlpool: 1512 + 64,
	coribalis: 1512 + 65,
	justyke: 1512 + 66,
	solotl: 1512 + 67,
	miasmite: 1512 + 68,
	dorsoil: 1512 + 69,
	saharascal: 1512 + 70,
	ababo: 1512 + 71,
	scattervein: 1512 + 72,
	cresceidon: 1512 + 73,

	pikachubelle: 1404 + 0,
	pikachupopstar: 1404 + 1,
	clefairy: 1404 + 2,
	clefable: 1404 + 3,
	jigglypuff: 1404 + 4,
	wigglytuff: 1404 + 5,
	dugtrioalola: 1404 + 6,
	poliwhirl: 1404 + 7,
	poliwrath: 1404 + 8,
	mukalola: 1404 + 9,
	kingler: 1404 + 10,
	croconaw: 1404 + 11,
	cleffa: 1404 + 12,
	igglybuff: 1404 + 13,
	politoed: 1404 + 14,
	unownb: 1404 + 15,
	unownc: 1404 + 16,
	unownd: 1404 + 17,
	unowne: 1404 + 18,
	unownf: 1404 + 19,
	unowng: 1404 + 20,
	unownh: 1404 + 21,
	unownj: 1404 + 22,
	unownk: 1404 + 23,
	unownl: 1404 + 24,
	unownm: 1404 + 25,
	unownn: 1404 + 26,
	unownp: 1404 + 27,
	unownq: 1404 + 28,
	unownquestion: 1404 + 29,
	unownr: 1404 + 30,
	unowns: 1404 + 31,
	unownt: 1404 + 32,
	unownv: 1404 + 33,
	unownz: 1404 + 34,
	sneasel: 1404 + 35,
	teddiursa: 1404 + 36,
	roselia: 1404 + 37,
	zangoose: 1404 + 38,
	seviper: 1404 + 39,
	castformsnowy: 1404 + 40,
	absolmega: 1404 + 41,
	absol: 1404 + 42,
	regirock: 1404 + 43,
	torterra: 1404 + 44,
	budew: 1404 + 45,
	roserade: 1404 + 46,
	magmortar: 1404 + 47,
	togekiss: 1404 + 48,
	rotomwash: 1404 + 49,
	shayminsky: 1404 + 50,
	emboar: 1404 + 51,
	pansear: 1404 + 52,
	simisear: 1404 + 53,
	drilbur: 1404 + 54,
	excadrill: 1404 + 55,
	sawk: 1404 + 56,
	lilligant: 1404 + 57,
	garbodor: 1404 + 58,
	solosis: 1404 + 59,
	vanilluxe: 1404 + 60,
	amoonguss: 1404 + 61,
	klink: 1404 + 62,
	klang: 1404 + 63,
	klinklang: 1404 + 64,
	litwick: 1404 + 65,
	golett: 1404 + 66,
	golurk: 1404 + 67,
	kyuremblack: 1404 + 68,
	kyuremwhite: 1404 + 69,
	kyurem: 1404 + 70,
	keldeoresolute: 1404 + 71,
	meloetta: 1404 + 72,
	greninja: 1404 + 73,
	greninjabond: 1404 + 73,
	greninjaash: 1404 + 74,
	furfroudebutante: 1404 + 75,
	barbaracle: 1404 + 76,
	clauncher: 1404 + 77,
	clawitzer: 1404 + 78,
	sylveon: 1404 + 79,
	klefki: 1404 + 80,
	zygarde: 1404 + 81,
	zygarde10: 1404 + 82,
	zygardecomplete: 1404 + 83,
	dartrix: 1404 + 84,
	steenee: 1404 + 85,
	tsareena: 1404 + 86,
	comfey: 1404 + 87,
	miniormeteor: 1404 + 88,
	minior: 1404 + 89,
	miniororange: 1404 + 90,
	minioryellow: 1404 + 91,
	miniorgreen: 1404 + 92,
	miniorblue: 1404 + 93,
	miniorviolet: 1404 + 94,
	miniorindigo: 1404 + 95,
	dhelmise: 1404 + 96,
	necrozma: 1404 + 97,
	marshadow: 1404 + 98,
	pikachuoriginal: 1404 + 99,
	pikachupartner: 1404 + 100,
	necrozmaduskmane: 1404 + 101,
	necrozmadawnwings: 1404 + 102,
	necrozmaultra: 1404 + 103,
	stakataka: 1404 + 104,
	blacephalon: 1404 + 105,
};